@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.google.com/specimen/Martian+Mono?category=Monospace');
@import url('https://fonts.google.com/specimen/Josefin+Sans?category=Sans+Serif');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Antique+B1&display=swap');

body {
  background-color: #FAFAFA;
  margin: 0;
  padding: 0;
}

ul, li, h1, h2, h3, h4, p, span {
  list-style-type: none;
  font-family: 'Shippori Antique B1', sans-serif;
}

div {
  list-style-type: none;
}

.CustomLink {
  font-family: 'Shippori Antique B1', sans-serif;
  list-style-type: none;
}

.notdisplayed {
  display: none;
}

.displayed {
  display: flex;
  margin: auto;
}

